<template>
  <div class="columns is-centered mt-5">
    <div class="column is-one-third">
      <div class="box has-text-centered">
        <p class="title">Contact via:</p>
        <div class="buttons is-centered">
          <a class="button is-success is-outlined" :href="whatsappLink" target="_blank">
            WhatsApp
          </a>
          <a class="button is-link is-outlined" :href="telegramLink" target="_blank">
            Telegram
          </a>
          <a class="button is-danger is-outlined" :href="mailtoLink">
            Email
          </a>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
name: 'ContactOptionsPage',
  data() {
    return {
      whatsappLink: "https://wa.me/+254757366184",
      telegramLink: "https://t.me/Cspiny",
      mailtoLink: "mailto:the2kingwriters@gmail.com",
    };
  },
};
</script>

<style scoped>
.box {
  animation: fadeIn 1s ease-in-out;
}

@keyframes fadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
</style>
