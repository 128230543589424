<template>
  <section class="hero is-medium is-dark is-bold custom-hero-background">
    <div class="hero-body">
      <div class="container has-text-centered">
        <h1 class="title has-text-light">Hire a Tutor for Your Assignments</h1>
        <h2 class="subtitle has-text-light">Get 50% off your first order!</h2>
        <button class="button is-primary is-large mt-5" @click="toggleModal">
          Hire Now
        </button>
      </div>
    </div>
    <!-- Modal -->
    <div class="modal" :class="{ 'is-active': isModalActive }">
      <div class="modal-background" @click="toggleModal"></div>
      <div class="modal-content">
        <div class="box has-text-centered">
          <p class="title">Contact via:</p>
          <div class="buttons is-centered">
            <a class="button is-success is-outlined" :href="whatsappLink" target="_blank">
              <i class="fab fa-whatsapp"></i>
              WhatsApp
            </a>
            <a class="button is-link is-outlined" :href="telegramLink" target="_blank">
              <i class="fab fa-telegram-plane"></i>
              Telegram
            </a>
            <a class="button is-danger is-outlined" :href="mailtoLink">
              <i class="fas fa-envelope"></i>
              Email
            </a>
          </div>
        </div>
      </div>
      <button class="modal-close is-large" aria-label="close" @click="toggleModal"></button>
    </div>
  </section>
</template>

<script>
export default {
  name: 'HerosectionPage',
  data() {
    return {
      isModalActive: false,
      whatsappLink: "https://wa.me/+254757366184",
      telegramLink: "https://t.me/Cspiny",
      mailtoLink: "mailto:writertopnotch66@gmail.com",
    };
  },
  methods: {
    toggleModal() {
      this.isModalActive = !this.isModalActive;
    },
  },
};
</script>

<style scoped>
.custom-hero-background {
  background-color: #2c3e50; /* Darkish background color */
}

.button.is-primary {
  background-color: #3498db; /* Adjust button color for contrast */
  border-color: #3498db;
}

.button.is-primary:hover {
  background-color: #2980b9;
  border-color: #2980b9;
}

.title, .subtitle {
  color: #ecf0f1; /* Light text color for contrast */
}

.modal-content {
  max-width: 400px;
  margin: 0 auto;
}

.box {
  animation: fadeIn 1s ease-in-out;
}

@keyframes fadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
</style>
